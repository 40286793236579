import React, {
  useEffect
} from 'react';

import WorkflowRequirementForm from '../../../workflow/requirement/form';

import {
  create
} from './axios'

const AbstractWorkflowRequirementForm = props => {

  const {
    requirement,
    currentEntityType,
    currentUser
  } = props;

  const defaultRequestParams = { current_entity_type_id: currentEntityType.hashid }
  const formName = 'workflow_requirement'

  useEffect(() => {
    if(!requirement.process_id){
      window.location = Routes.new_abstract_workflow_requirement_path(defaultRequestParams)
    }
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementForm
          requirement={ requirement }
          configuration={{
            formName: formName,
            defaultRequestParams: defaultRequestParams
          }}
          routes={{
            indexRequirementsPath: Routes.abstract_workflow_requirements_path,
            showRequirementPath: Routes.abstract_workflow_requirement_path
          }}
          services={{
            createRequirement: create,
          }}
        />
      </div>
    </div>
  )
}

export default AbstractWorkflowRequirementForm;
