import React, { useState } from 'react';

import OtpInput from 'react-otp-input';

const OtpInputForm = props => {

  const [pinCode, setPinCode] = useState(props?.pinCode || '')
  const formName = props?.formName || '';

  return(
    <div className="row">
      <div className="col-12">
        <OtpInput
          value={ pinCode }
          onChange={ setPinCode }
          numInputs={ 6 }
          renderSeparator={ <span></span> }
          inputType={ 'password' }
          inputStyle={ 'form-control ml-2 text-center' }
          skipDefaultStyles={ true }
          renderInput={ (props) => <input {...props} /> }
        />

        <input
          type="hidden"
          name={ `${ formName }[otp_code_token]` }
          value={ pinCode }
        />
      </div>
    </div>
  )
}

export default OtpInputForm;
