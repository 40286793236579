import React, { useState, useEffect, useRef } from 'react';

import paginationFactory from "react-bootstrap-table2-paginator";
import { options as optionPagination } from "../../../helper/pagination";

import filterFactory from 'react-bootstrap-table2-filter';
import useFilterrific from "../../../hooks/useFilterrific";
import useFilterRequestParams from "../../../hooks/useFilterRequestParams";

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter";

const BulkNotificationFillControldocFormDocumentList = props => {
  const {
    bootstrapTableConfiguration: {
      tableColumns,
      ref: bootstrapTableRef
    },
    actions: {
      indexFormDocuments
    },
    configuration: {
      defaultRequestParams,
    }
  } = props;

  const formName = "bulk_notification_fill";

  const [filterrific, setFilterrific] = useFilterrific({...filterrific});
  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});
  const [controldocFormDocuments, setControldocFormDocuments] = useState([]);
  const [selectedControldocFormDocuments, setSelectedControldocFormDocuments] = useState([]);

  useEffect(() => {
    fetchControldocFormDocuments();
  }, [requestFilterParams]);

  const fetchControldocFormDocuments = () => {
    let _requestFilterParams = { ...requestFilterParams }
    requestFilterParams['filterrific']['by_aasm_state'] = 'pending'

    indexFormDocuments(_requestFilterParams, response => {
      setControldocFormDocuments(response.data.documents)
    });
  };

  const selectRow = {
    mode: 'checkbox',
    style: { width: '10px' },
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    clickToSelect: true,
    selected: _.map(selectedControldocFormDocuments || [], document => document.id),
    onSelect: (row, isSelected) => {
      onSelectFormDocument(isSelected, row)
    },
    onSelectAll: (isSelected, rows) => {
      onSelectAllControldocFormDocuments(isSelected, rows);
    }
  };

  const onSelectFormDocument = (isSelected, row) => {
    let _selectedControldocFormDocuments = [...selectedControldocFormDocuments] ;

    if (isSelected) {
      _selectedControldocFormDocuments.push(
        _.find(controldocFormDocuments, document => document.id === row.id)
      );
    } else{
      _.remove(_selectedControldocFormDocuments, document => document.id === row.id )
    }

    setSelectedControldocFormDocuments(_selectedControldocFormDocuments)
  };

  const onSelectAllControldocFormDocuments = (isSelected, rows) => {
    let _selectedControldocFormDocuments = [...selectedControldocFormDocuments];

    if (isSelected) {
      _selectedControldocFormDocuments = [ ..._selectedControldocFormDocuments, ...rows ]
    } else {
      let removedIds = _.map(rows, row => row.id);
      _.remove(_selectedControldocFormDocuments, (document, index) => {
        return _.includes(removedIds, document.id)
      })
    }

    setSelectedControldocFormDocuments(_selectedControldocFormDocuments)
  };

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTableRef
    })
  };

  const drawSelectedControldocFormDocumentsInput = () => {
    return (
      <>
        {_.map(selectedControldocFormDocuments, function (document) {
          return (
            <div key={`controldoc_form_document-input-${document.id}`}>
              <input
                type="hidden"
                name={`${formName}[items_attributes][][controldoc_form_document_id]`}
                value={document.id}
              />
            </div>
          );
        })}
      </>
    );
  };

  const drawControldocFormDocumentList = () => {
    return(
      <BootstrapTableCustomFilter
        showBulkMyFilter={ true }
        remote={ true }
        keyField="id"
        selectRow={ selectRow }
        data={ controldocFormDocuments }
        filter={ filterFactory() }
        columns={ tableColumns }
        pagination={
          paginationFactory(
            optionPagination({
              totalSize: controldocFormDocuments?.length,
              sizePerPage: requestFilterParams?.per_page,
            })
          )
        }
        classes='table-responsive height-600'
        onTableChange={ handleTableChange }
        _ref={ bootstrapTableRef }
      />
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        { drawControldocFormDocumentList() }
        { drawSelectedControldocFormDocumentsInput()}
      </div>
    </div>
  )
}

export default BulkNotificationFillControldocFormDocumentList;
