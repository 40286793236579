import React, {
  useState,
  useEffect,
  useCallback
} from 'react';

import I18n from "i18n-js";

import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";

import { options as optionPagination } from "../../../helper/pagination";

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter";

import useFilterRequestParams from "../../../hooks/useFilterRequestParams";

const BulkRequestDocumentEntityTab = props => {

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const [entities, setEntities] = useState({ data: [] })

  const {
    bulkRequest,
    setBulkRequest,
    bootstrapTableConfiguration: {
      tableColumns,
      ref: bootstrapTableRef
    },
    actions: {
      indexEntities
    },
    configuration: {
      defaultRequestParams,
      entity: entityConfiguration
    }
  } = props;

  const { key: entityKey } = entityConfiguration;

  useEffect(() => {
    fetchEntities()
  }, [requestFilterParams])

  const fetchEntities = () => {
    let requestParams = {
      ... defaultRequestParams,
      ...requestFilterParams
    };

    indexEntities(requestParams, response => {
      setEntities({
        data: response.data[entityKey],
        currentPage: response.data.current_page,
        in_page: response.data.in_page,
        total: response.data.total,
        total_pages: response.data.total_pages
      })
    })
  }

  // ---------------- START TABLE ----------------

  const onTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTableRef
    })
  };

  const onSelectChange = (isSelected, row) => {
    let _entities = [ ... bulkRequest[entityKey] ]

    if(isSelected){
      _entities.push(
        _.find(entities.data, entity => String(entity.id) == String(row.id))
      )
    } else {
      _.remove(_entities, entity => String(entity.id) == String(row.id))
    }

    setBulkRequest(prevState => {
      return { ...prevState, [entityKey]: _entities }
    })
  }

  const onSelectAllChange = (isSelected, rows) => {
    let _entities = [ ... bulkRequest[entityKey] ];

    if(isSelected){
      _entities = [ ... _entities, ...rows ]
    } else {
      let removedIds = _.map(rows, row => String(row.id));
      _.remove(_entities, (entity, index) => {
        return _.includes(removedIds, String(entity.id))
      })
    }

    setBulkRequest(prevState => {
      return { ... prevState, [entityKey]: _entities }
    })
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    selected: _.map(bulkRequest[entityKey] || [], entity => entity.id),
    onSelect: (row, isSelected, rowIndex, e) => {
      onSelectChange(isSelected, row)
    },
    onSelectAll: (isSelected, rows, e) => {
      onSelectAllChange(isSelected, rows)
    },
  };

  const entitiesErrors = () => {
    if(_.has(bulkRequest, 'errors.entity_items')){
      return(
        <div className="alert alert-danger">
          <ul className='mb-0'>
            { _.map(bulkRequest.errors.entity_items, (error) => {
              return(
                <li key={ _.uniqueId("entityError-") }>
                  { error }
                </li>
              )
            })}
          </ul>
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">

        { entitiesErrors() }

        <BootstrapTableCustomFilter
          remote={ true }
          showBulkMyFilter={ true }
          keyField="id"
          selectRow={ selectRow }
          data={ entities.data }
          filter={ filterFactory() }
          columns={ tableColumns }
          pagination={ paginationFactory(optionPagination({
            totalSize: entities.total,
            sizePerPage: requestFilterParams?.per_page,
          })) }
          classes="table-responsive height-600"
          onTableChange={ onTableChange }
          _ref={ bootstrapTableRef }
        />
      </div>
    </div>
  )
}

export default BulkRequestDocumentEntityTab;
