import React from 'react';

import WorkflowRequirementCommentBox from '../../../../workflow/requirement/comment/box'

const PortalWorkflowRequirementCommentBox = props => {
  const {
    requirement,
    currentCustomer,
    configuration: {
      tenant
    }
  } = props;

  const commentDefaultRequestParams = {
    tenant: tenant,
    resource_id: requirement.hashid,
    customer_id: currentCustomer.hashid
  }

  const requirementDefaultRequestParams = {
    tenant: tenant,
    id: requirement.hashid
  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementCommentBox
          requirement={ requirement }
          configuration={{
            commentDefaultRequestParams: commentDefaultRequestParams,
            requirementDefaultRequestParams: requirementDefaultRequestParams,
            context: 'portal',
            showInfoStage: true
          }}
          routes={{
            downloadAttachCommentPath: Routes.download_attach_portal_comment_workflow_requirement_path,
            downloadOutputFilePath: Routes.download_output_file_item_portal_workflow_requirement_stage_path,
            indexCommentPath: Routes.portal_comment_workflow_requirements_path,
            createCommentPath: Routes.portal_comment_workflow_requirements_path
          }}
        />
      </div>
    </div>
  )
}

export default PortalWorkflowRequirementCommentBox;
