import React, {
  useState,
  useEffect
} from 'react';

import {
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowRequirementStageModalForm from './modal_form';

const aasmStateEventConfig = (event) => {
  if(event == 'to_proceeded'){
    return { style: 'success', icon: 'check-circle' }
  } else if (event == 'to_completed'){
    return { style: 'success', icon: 'clipboard-check' }
  } else if (event == 'to_returned'){
    return { style: 'warning', icon: 'undo' }
  } else if (event == 'to_rejected'){
    return { style: 'danger', icon: 'ban' }
  }
}

const WorkflowRequirementStageToolbarEvent = props => {

  const {
    configuration,
    routes,
    callbacks,
    services,
    policies: {
      canEdit: policyCanEdit
    },
  } = props;

  const [requirementStage, setRequirementStage] = useState(props?.requirementStage);

  const onChangeRequirementAasmEvent = (aasmEvent) => {
    setRequirementStage(prevState => {
      return { ... prevState, aasm_state_event: aasmEvent}
    })
  }

  const buttonEventActions = () => {
    if(requirementStage?.events?.permitted && policyCanEdit){
      return(
        _.map(requirementStage.events.permitted, permittedEvent => {
          const config = aasmStateEventConfig(permittedEvent?.event)
          return(
            <Button
              key={ `EventButton-${ permittedEvent.event }` }
              className='ml-1'
              outline
              color={ config?.style }
              size={ 'sm' }
              onClick={ e => onChangeRequirementAasmEvent(permittedEvent.event) }
            >
              <i className={ `fas fa-${ config?.icon }` }></i>
              &nbsp;
              { permittedEvent?.human_name }
            </Button>
          )
        })
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12 text-end">
        { buttonEventActions() }
        <WorkflowRequirementStageModalForm
          requirementStage={ requirementStage }
          configuration={ configuration }
          callbacks={{
            ...callbacks, onChangeRequirementAasmEvent: onChangeRequirementAasmEvent
          }}
          routes={ routes }
          services={ services }
        />
      </div>
    </div>
  )
}

export default WorkflowRequirementStageToolbarEvent;
