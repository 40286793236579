import React, { useEffect, useState, useRef } from "react";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button
} from 'reactstrap'

import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";

import FieldError from "../../../helper/FieldError";
import { inputId, inputName, limitCharacters } from '../../../helper/form';

import I18n from 'i18n-js';

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter";
import BootstrapTableFilterHeader from "../../../helper/bootstrap-table/filter/filter_header";
import BootstrapTableSortHeader from "../../../helper/bootstrap-table/filter/sort_header";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pageListRenderer, options as optionPagination, } from "../../../helper/pagination";

import useFilterrific from "../../../hooks/useFilterrific";

import useFilterRequestParams from "../../../hooks/useFilterRequestParams";

import { index as indexCorporateAgreement } from "../../agreement/axios";

const CorporateCheckListAgreementProcessEntityTable = props => {

  const {companies} = props?.dataFilters || {};

  const [agreements, setAgreements] = useState(props?.agreements || []);

  const [entityIds, setEntityIds] = useState(props?.entityIds || [])

  const customFields = props?.customFields

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(agreements.length || 10);

  const [filterrific, setFilterrific] = useFilterrific({});

  const [perPage, setPerPage] = useState(10);

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  const bootstrapTable = useRef(null);

  const columns = [
    {
      dataField: "agreement_config.client.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement_config.client_id"),
      filterByFilterrific: "by_client",
      filter: selectFilter({
        placeholder: `Seleccione una empresa ${ _.lowerCase(I18n.t("activerecord.attributes.corporate/agreement_config.client_id")) }`,
        options: _.map(companies, company => ( { value: company.id, label: company.name } ) ),
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "agreement_config.provider.company_config.name",
      text: I18n.t("activerecord.attributes.corporate/agreement_config.provider_id"),
      filterByFilterrific: "by_provider",
      filter: selectFilter({
        placeholder: `Seleccione una empresa ${ _.lowerCase(I18n.t("activerecord.attributes.corporate/agreement_config.provider_id")) }`,
        options: _.map(companies, company => ( { value: company.id, label: company.name } ) ),
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "start_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement_config.start_date"),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "end_date_to_s",
      text: I18n.t("activerecord.attributes.corporate/agreement_config.end_date"),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t('no_entry'),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
  ]

  const getCustomFields = customFieldsHeaders => {
    return _.map(customFieldsHeaders, function (custom) {
      return {
        dataField: `custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `agreement.custom_fields.${ custom.code }`,
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        filter: textFilter({}),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const columnsAgreements = _.concat(columns, getCustomFields(customFields))

  const handleSelectAll = (isSelected, entities) => {
    let _entityIds = [ ... entityIds ];

    if(isSelected){
      _.each(entities, h => _entityIds.push(h.id))
    } else {
      let ids = _.map(entities, h => String(h.id));

      _.remove(_entityIds, function(entityId, index){
        return _.includes(ids, String(entityId))
      })
    }

    if(_.isFunction(props?.callbackHandleProcessData)){
      let _event = { target: { value: _entityIds } }

      props.callbackHandleProcessData(_event, 'entity_ids')
    }
  }

  const handleOnSelect = ( isSelected, row) => {
    let _entityIds = [ ... entityIds ]

    if(isSelected){
      _entityIds.push(row.id)
    } else {
      _.remove(_entityIds, id => String(id) == String(row.id) )
    }

    if(_.isFunction(props?.callbackHandleProcessData)){
      let _event = { target: { value: _entityIds } }

      props.callbackHandleProcessData(_event, 'entity_ids')
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: entityIds,
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      handleOnSelect(isSelect, row)
    },
    onSelectAll: (isSelect, rows, e) => {
      handleSelectAll(isSelect, rows);
    },
  };

  useEffect(() => {
    setEntityIds(props?.entityIds || [])
  }, [props])

  useEffect(() => {
    getAgreements()
  }, []);

  useEffect(() => {
    getAgreements();
  }, [requestFilterParams]);

  const getAgreements = () => {
    indexCorporateAgreement(requestFilterParams, response => {
      setAgreements(response.data.agreements);
      setTotalSize(response.data.total);
    });
  };

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: bootstrapTable
    })
  }

  const drawEntityErrors = () => {
    if(props?.errors){
      return(
        <div className="alert alert-danger text-center">
          { props.errors }
        </div>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-body">
            { drawEntityErrors() }
            <BootstrapTableCustomFilter
              remote={ true }
              keyField="id"
              selectRow={ selectRow }
              data={ agreements }
              filter={ filterFactory() }
              columns={ columnsAgreements }
              pagination={paginationFactory(optionPagination({
                totalSize: totalSize,
                sizePerPage: requestFilterParams?.per_page,
              })) }
              classes="table-responsive height-600"
              onTableChange={ handleTableChange }
              _ref={ bootstrapTable }
            />
          </div>
        </div>
      </div>
    </div>
  )

}

export default CorporateCheckListAgreementProcessEntityTable
