import React, { useState, useEffect, useRef } from "react";
import I18n from "i18n-js";

import BulkRequestDestroyDocumentTable from "../../../../bulk/request/destroy_document/table";
import BootstrapTableSortHeader from "../../../../helper/bootstrap-table/filter/sort_header";
import BootstrapTableFilterHeader from "../../../../helper/bootstrap-table/filter/filter_header";

import { index as indexLaboralHiringDocument } from "../../../hiring_document/axios";
import { create } from "../destroy_document/axios";

import filterFactory, {
  textFilter,
  selectFilter
} from "react-bootstrap-table2-filter";
import _ from "lodash";

import { limitCharacters } from "../../../../helper/form";

const LaboralBulkRequestDestroyDocumentForm = props => {
  const { documentTypes, companies, aasmStates, securityLayers } = props?.filters;

  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);

  const { currentCustomer} = props;

  const bootstrapTable = useRef(null);

  const getEmployeeSecurityLayerColumn = () => {
    if(currentCustomer.security_layer && securityLayers?.length > 0){
      return {
        dataField: `entity.employee.security_layer.name`,
        text: I18n.t("activerecord.attributes.laboral/employee.security_layer_id"),
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        filterByFilterrific: `by_employee_security_layer_id`,
        myFilter: `employee.security_layer.name`,
        filter: selectFilter({
          placeholder: `Seleccione una ${ I18n.t("activerecord.attributes.laboral/employee.security_layer_id") }`,
          options: _.map(securityLayers, securityLayer => ({
            value: securityLayer.id,
            label: securityLayer.name,
          })),
        }),
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      }
    } else {
      return [];
    }
  }

  const columns = [
    {
      dataField: "entity.employee.employee_config.name",
      text: `${I18n.t("activerecord.attributes.laboral/employee_config.name")}`,
      filterByFilterrific: "search_by_employee_name",
      sortByFilterrific: "employee_name_",
      myFilter: 'employee.name',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/employee_config.name"
        )}`
      }),
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.employee.employee_config.email",
      text: `${I18n.t("activerecord.attributes.laboral/employee_config.email")}`,
      filterByFilterrific: "search_by_employee_email",
      sortByFilterrific: "employee_email_",
      myFilter: 'employee.email',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/employee_config.email"
        )}`
      }),
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.company.company_config.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.company")}`,
      filterByFilterrific: "by_company",
      sortByFilterrific: "company_name_",
      myFilter: 'company.name',
      filter: selectFilter({
        options: _.map(companies, company => ({
          value: company.id,
          label: company.name
        }))
      }),
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.start_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.start_date")}`,
      filterByFilterrific: 'search_by_hiring_start_date',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/hiring.start_date"
        )}`
      }),
      sortByFilterrific: "start_date_",
      myFilter: 'hiring.start_date_to_s',
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "entity.end_date_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring.end_date")}`,
      filterByFilterrific: 'search_by_hiring_end_date',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/hiring.start_date"
        )}`
      }),
      myFilter: 'hiring.end_date_to_s',
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "document_type.name",
      text: `${I18n.t("activerecord.attributes.laboral/hiring_document.document_type")}`,
      filterByFilterrific: "by_document_type",
      sortByFilterrific: "document_type_name_",
      myFilter: 'document_type.name',
      filter: selectFilter({
        options: _.map(documentTypes, document_type => ({
          value: document_type.id,
          label: document_type.name,
        })),
      }),
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "label",
      text: `${I18n.t("activerecord.attributes.laboral/hiring_document.label")}`,
      filterByFilterrific: "search_by_label",
      sortByFilterrific: "label_",
      myFilter: 'document.label',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/hiring_document.label"
        )}`
      }),
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "aasm_state",
      text: `${I18n.t("activerecord.attributes.laboral/hiring_document.aasm_state.one")}`,
      filterByFilterrific: "by_aasm_state",
      sortByFilterrific: "aasm_state_",
      filter: selectFilter({
        placeholder: `Seleccione un estado`,
        options: aasmStates,
      }),
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      formatter: (value, row, index) => I18n.t(`activerecord.attributes.laboral/hiring_document.aasm_state.${value}`) || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    },
    {
      dataField: "created_at_to_s",
      text: `${I18n.t("activerecord.attributes.laboral/hiring_document.created_at")}`,
      filterByFilterrific: 'search_by_created_at',
      filter: textFilter({
        placeholder: `Ingrese el ${I18n.t(
          "activerecord.attributes.laboral/hiring_document.created_at"
        )}`
      }),
      sortByFilterrific: "created_at_to_s_",
      myFilter: 'document.created_at_to_s',
      sort: true,
      headerStyle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
      },
      sortCaret: (order, column) => (
        <BootstrapTableSortHeader {...{ order, column }} />
      ),
      formatter: (value, row, index) => value || I18n.t("no_entry"),
      headerFormatter: (column, colIndex, args) => (
        <BootstrapTableFilterHeader
          {...{ column, colIndex, args, bootstrapTable }}
        />
      ),
    }
  ]

  const getCustomFields = customFields => {
    return _.map(customFields, function (custom) {
      return {
        dataField: `entity.custom_fields.${custom.code}`,
        text: _.capitalize(custom.label),
        filterByFilterrific: `custom_fields.${ custom.code }`,
        myFilter: `hiring.custom_fields.${ custom.code }`,
        filter: textFilter({}),
        headerStyle: {
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
        },
        formatter: (value, row, index) => { return limitCharacters(value) },
        headerFormatter: (column, colIndex, args) => (
          <BootstrapTableFilterHeader
            {...{ column, colIndex, args, bootstrapTable }}
          />
        ),
      };
    });
  };

  const documentColumns = _.concat(
    getEmployeeSecurityLayerColumn(), columns, getCustomFields(props?.customFields)
  );

  return (
    <div>
      <BulkRequestDestroyDocumentTable
        showBulkMyFilter={ true }
        columns={documentColumns}
        _ref={bootstrapTable}
        indexDocument={indexLaboralHiringDocument}
        createService={create}
        bulkRequest={bulkRequest}
        buttonActions={{
          show: Routes.laboral_bulk_request_destroy_document_path,
          back: Routes.laboral_bulk_request_destroy_documents_path(),
        }}
      />
    </div>
  )
}

export default LaboralBulkRequestDestroyDocumentForm;
