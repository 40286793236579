import axios from "axios";

export const create = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'post',
    url: Routes.abstract_workflow_requirements_path({...{...requestParams}, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
