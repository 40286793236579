import React, {
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
  drawTooltipHelp
} from '../../helper/form';

import {
  fieldItemIsRequired as isRequired,
  fieldItemHtmlInputType as htmlInputType
} from '../requirement_stage/helper';

const WorkflowRequirementStageFormItemFieldAttributes = props => {
  const {
    fieldItem,
    configuration: {
      formName, index
    },
    callbacks: {
      onChangeFieldItem: callbackOnChangeFieldItem
    }
  } = props;

  const onChangeFieldItem = (event, key) => {
    let _fieldItem = { ... fieldItem };
    let value = event.target.value;

    _fieldItem[key] = value;

    callbackOnChangeFieldItem(_fieldItem, _fieldItem.item.id)
  }

  const helpingText = () => {
    if(fieldItem.helping_text){
      return (
        <span className='text-muted small'>{ fieldItem.helping_text }</span>
      )
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <FieldError errors={ _.get(fieldItem, 'errors.value') || [] }>
            <Label
              className={ isRequired(fieldItem) ? 'required' : ''}
              htmlFor={ inputId(formName, 'value') }
            >
              { fieldItem.name }
            </Label>
            <Input
              type={ htmlInputType(fieldItem) }
              required={ isRequired(fieldItem) }
              onChange={ e => onChangeFieldItem(e, 'value') }
              value={ fieldItem?.value || '' }
              invalid={ _.has(fieldItem.errors, 'value') }
              id={ inputId(formName, 'value') }
              name={ inputName(formName, 'value') }
            />
            { helpingText() }
          </FieldError>
        </FormGroup>
      </div>
    </div>
  )
}

export default React.memo(WorkflowRequirementStageFormItemFieldAttributes);
