import React from 'react';

import WorkflowRequirementStageToolbarEvent from '../../../workflow/requirement_stage/toolbar_event'

import {
  update
} from './axios'

const PortalWorkflowRequirementStageToolbarEvent = props => {

  const {
    requirementStage,
    policies,
    configuration: {
      tenant
    }
  } = props;


  const defaultRequestParams = {
    id: requirementStage.hashid,
    tenant: tenant
  }

  const onSuccessSubmit = () => {
    window.location = Routes.portal_workflow_requirement_path({
      tenant: tenant,
      id: requirementStage.requirement.hashid,
      _options: true
    });
  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementStageToolbarEvent
          requirementStage={ requirementStage }
          policies={ policies }
          configuration={{
            defaultRequestParams: defaultRequestParams
          }}
          callbacks={{
            onSuccessSubmit: onSuccessSubmit
          }}
          services={{
            updateRequirementStage: update
          }}
        />
      </div>
    </div>
  )
}

export default PortalWorkflowRequirementStageToolbarEvent;

