import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import {
Row,
Col } from 'reactstrap';

import PublicTutorialTourCapsuleList from "../tour_capsule/list";
import PublicTutorialTourCapsuleCurrentItem from '../tour_capsule/current_item';
import TourHeader from './tour_header';

import CrmTutorialTourContext from './context/tour_context';

const PublicTutorialTourVirtualTour = props => {

  const [tour, setTour] = useState(props?.tour || {});
  const [currentTourCapsule, setCurrentTourCapsule] = useState(
    _.find(tour.tour_capsules, tourCapsule => !tourCapsule.viewed_at) || _.head(tour.tour_capsules)
  )

  const onChangeTourCapsule = useCallback((tourCapsuleId, attributes) => {
    setTour(prevState => {
      return {
        ... prevState,
        tour_capsules: _.map(prevState.tour_capsules, tourCapsule => {
          if(tourCapsule.id == tourCapsuleId){
            return { ... tourCapsule, ... attributes }
          } else {
            return tourCapsule
          }
        })
      }
    })
  }, [])

  const setNextCapsule = () => {
    const capsuleIndex = _.findIndex(tour.tour_capsules, tourCapsule => {
      return String(tourCapsule.id) == String(currentTourCapsule.id)
    })

    if(tour.tour_capsules[capsuleIndex + 1]){
      const nextTourCapsule = tour.tour_capsules[capsuleIndex + 1];
      setCurrentTourCapsule(nextTourCapsule)
    }
  }

  return(
    <CrmTutorialTourContext.Provider
      value={{
        configuration: {
          tourCapsuleDefaultRequestParams: {}
        },
        callbacks: {
          onChangeTourCapsule,
          setNextCapsule: setNextCapsule
        }
      }}
    >
      <div className="main-tour">
        <TourHeader
          tour={ tour }
          currentTourCapsule={ currentTourCapsule }
        />
        <section className="tour-body bg-real-light">
          <Row>
            <Col md="8" lg="9">
              <div className="vh-100 overflow-scroll pb-4">
                <PublicTutorialTourCapsuleCurrentItem
                  key={ `PublicTutorialTourCapsuleCurrentItem-${ currentTourCapsule.id }` }
                  currentTourCapsule={ currentTourCapsule }
                />
              </div>
            </Col>
            <Col md="4" lg="3" className="bg-white p-3">
              <nav className="navbar navbar-light navbar-expand-md bg-white">
                <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasPlayList" aria-labelledby="offcanvasPlaylistLabel">
                  <div className="offcanvas-header shadow-sm">
                    <h5 className="offcanvas-title fw-bold" id="offcanvasPlayListLabel">
                      <i className="fas fa-th-list mr-2"></i>
                      Contenido del Tour
                    </h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body flex-column">
                    <h2 className="h6 fw-bold d-none d-md-block">
                      <i className="fas fa-th-list mr-2"></i>
                      Contenido del Tour
                    </h2>
                    <PublicTutorialTourCapsuleList
                      currentTourCapsule={ currentTourCapsule }
                      callbacks={{
                        setCurrentTourCapsule: setCurrentTourCapsule
                      }}

                      tour={ tour }
                    />
                  </div>
                </div>
              </nav>
            </Col>
          </Row>
        </section>
      </div>
    </CrmTutorialTourContext.Provider>
  )
}

export default PublicTutorialTourVirtualTour;
