import React, {
  useEffect
} from 'react';
import WorkflowRequirementForm from '../../../workflow/requirement/form';
import { create } from './axios';

const PortalWorkflowRequirementForm = props => {
  const {
    requirement,
    currentTenant
  } = props;

  const defaultRequestParams = { tenant: currentTenant, process_id: requirement.process_id }
  const formName = 'workflow_requirement'

  useEffect(() => {
    if (!requirement.process_id) {
      window.location = Routes.new_portal_workflow_requirement_path(defaultRequestParams)
    }
  }, [])

  return (
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementForm
          requirement={requirement}
          configuration={{
            formName: formName,
            defaultRequestParams: defaultRequestParams
          }}
          routes={{
            indexRequirementsPath: Routes.portal_workflow_requirements_path,
            showRequirementPath: Routes.portal_workflow_requirement_path
          }}
          services={{
            createRequirement: create,
          }}
        />
      </div>
    </div>
  )
}

export default PortalWorkflowRequirementForm;
