import React, { useState, useEffect, useRef } from "react";
import { FormGroup, FormFeedback, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import Select from "react-select";
import I18n from 'i18n-js';

import { index } from "../../../laboral/bulk/my_filter/axios";


const LaboralBulkMyFilterList = props => {
  const bootstrapTableRef = props?.bootstrapTableRef || {}
  const currFilters = bootstrapTableRef?.current?.filterContext?.currFilters;

  const [myFilters, setMyFilters] = useState([])

  const [selectedFilter, setSelectedFilter] = useState({value: 0})

  useEffect(() => {
    let hasFilter = _.some(_.map(currFilters, f => _.has(f, 'filterVal')))

    if(!hasFilter){
      setSelectedFilter({})
    }
  }, [props])

  useEffect(() => {
    getMyFilters()
  }, [])

  useEffect(() => {
    if(props?.myFilterCreated){
      getMyFilters()

      if(_.isFunction(props?.setMyFilterCreated)){
        props.setMyFilterCreated(false)
      }
    }
  }, [props])

  useEffect(() => {
    if(_.keys(selectedFilter).length > 0){
      let filters = selectedFilter?.filters?.filters || {};

      _.each(filters, function(value, key){
        let dataField = _.find(bootstrapTableRef.current.table.props.columns, { myFilter: key } );
        if(_.keys(dataField)){
          currFilters[dataField?.dataField] = { filterVal: value || ""}
        }
      })
      bootstrapTableRef.current.handleRemoteFilterChange(currFilters);
    }
  }, [selectedFilter])

  const getMyFilters = () => {
    index(response => {
      setMyFilters(response.data.my_filters);
    });
  }

  const myFiltersOptionSelect = () => {
    return _.map(myFilters, f => ( { value: f.id, label: f.name }));
  }

  const myFilterOptionSelected = () => {
    return selectedFilter ? { value: selectedFilter.id, label: selectedFilter.name } : ''
  }

  const handleSelectFilter = event => {
    let currentFilter = _.find(myFilters, {id: event.value } );
    if(props?.bootstrapTableRef.current.props.showSearchFilters){
      props?.bootstrapTableRef.current.props.handleShowSearchFilters({})
    }
    setTimeout(() => {
      setSelectedFilter(currentFilter);
    }, 20);

  }

  const drawSelect = () => {
    if(myFilters.length > 0){
      return(
        <div style={{ width: '500px'}}>
          <Select
            options={ myFiltersOptionSelect() }
            onChange={e => handleSelectFilter(e) }
            className="basic-single d-inline"
            classNamePrefix="select"
            value={ myFilterOptionSelected() }
            placeholder=' -- Seleccione Filtro -- '
          />
        </div>
      )
    }
  }


  return(
    <>
      { drawSelect() }
    </>
  )
}

export default LaboralBulkMyFilterList;
