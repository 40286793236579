import React, {
  useContext
} from 'react';

import I18n from 'i18n-js';

import WorkflowStageItemFieldList from '../stage_field_item/list';
import WorkflowStageItemFileList from '../stage_file_item/list';

import WorkflowDiagramContext from '../diagram_context';

const KARIN_KAW_DEFAULT_GROUP_NAME = [
  I18n.t('karin_law.workflow.requirements.form.victim.title'),
  I18n.t('karin_law.workflow.requirements.form.representative.title'),
  I18n.t('karin_law.workflow.requirements.form.perpetrator.title'),
]

const WorkflowOutputStageFormAttributes = props => {
  const {
    currentNode,
    setCurrentNode,
    outputStageForm: {
      field_items: fieldItems,
      file_items: fileItems
    }
  } = props;

  const {
    configuration: {
      context
    },
    callbacks: {
      isNodeInput,
      isNodeOutput,
      isNodeDefault,
      isNodeShortlist
    },
  } = useContext(WorkflowDiagramContext);

  const groupNameOptions = () => {
    let groups = []
    if(isNodeInput(currentNode) && context == 'karin_law'){
      groups = _.concat(groups, KARIN_KAW_DEFAULT_GROUP_NAME)
    }

    groups = _.concat(
      groups,
      _.map(fieldItems, 'group_name')
    )

    groups = _.concat(
      groups,
      _.map(fileItems, 'group_name')
    )

    let data = _.reject(groups, group => { return !group })

    const uniqGroups = _.uniq(data);

    const sorteredGroups = _.sortBy(uniqGroups);

    return sorteredGroups
  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowStageItemFieldList
          fieldItems={ fieldItems }
          currentNode={ currentNode }
          setCurrentNode={ setCurrentNode }
          configuration={{
            stageForm: 'output_stage_form',
            showGroupName: true
          }}
          data={{
            groupNameOptions: groupNameOptions()
          }}
        />

        <WorkflowStageItemFileList
          fileItems={ fileItems }
          currentNode={ currentNode }
          setCurrentNode={ setCurrentNode }
          configuration={{
            stageForm: 'output_stage_form',
            showGroupName: true
          }}
          data={{
            groupNameOptions: groupNameOptions()
          }}
        />
      </div>
    </div>
  )
}

export default WorkflowOutputStageFormAttributes;
