import React, { useState, useEffect } from "react";
import I18n from "i18n-js";

import BootstrapTableCustomFilter from "../../../helper/bootstrap-table/table_custom_filter"

import { FormGroup, Button, Label } from "reactstrap";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pageListRenderer, options as optionPagination } from "../../../helper/pagination";
import _ from "lodash";
import useFilterrific from "../../../hooks/useFilterrific";

import useFilterRequestParams from "../../../hooks/useFilterRequestParams";

const BulkRequestDestroyDocumentTable = props => {
  const formName = props?.formName || "bulk_request_destroy_document";

  const { columns, indexDocument, buttonActions, createService, _ref } = props;

  const [documents, setDocuments] = useState(props?.documents || []);
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false)

  const [page, setPage] =  useState(1)
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] =  useState(documents.length || 10)
  const [filterrific, setFilterrific] = useFilterrific({})
  const [perPage, setPerPage] = useState(10);

  const [requestFilterParams, setRequestFilterParams] = useFilterRequestParams({});

  useEffect(() => {
    getDocuments()
  }, [requestFilterParams])

  const getDocuments = () => {
    indexDocument(requestFilterParams, response => {
      setDocuments(response.data.documents);
      setTotalSize(response.data.total);
    });
  };

  const handleConfirmSubmit = () => {
    swalWithBootstrap
      .fire({
        title: I18n.t(
          "laboral.bulk.request.destroy_documents.form.confirm.title"
        ),
        html: I18n.t(
          "laboral.bulk.request.destroy_documents.form.confirm.message"
        ),
      })
      .then(result => {
        if (result.isConfirmed) {
          handleSubmit();
        }
      });
  };

  const drawTextSubmitButton = () => {
    if (disabledSubmitButton) {
      return "Guardando información ...";
    } else {
      return I18n.t("actions.save");
    }
  };

  const drawSubmitButton = () => {
    return (
      <Button
        color="success"
        className="ml-2"
        onClick={ handleConfirmSubmit }
        disabled={ disabledSubmitButton }
      >
        { drawTextSubmitButton() }
      </Button>
    );
  };

  const handleSubmit = () => {
    let formData = getFormData();
    setDisabledSubmitButton(true);

    createService(formData, response => {
      if (response.status == 201) {
        window.location = buttonActions.show(response.data.id);
      } else if (response.status == 422) {
        setBulkRequest(response.data);
        setDisabledSubmitButton(false);
      }
    });
  };

  const getFormData = () => {
    let _formData = new FormData();
    _.each(bulkRequest.items, function (item, _index) {
      _formData.append(`${formName}[items_attributes][${_index}][document_id]`, item.document_id || "");
    });

    return _formData;
  }

  const handleTableChange = (type, args) => {
    setRequestFilterParams({
      type: type,
      args: args,
      bootstrapTable: _ref
    })
  }

  const handleBulkRequestData = (e, key) => {
    let value = e?.target?.value;
    let _bulkRequest = { ...bulkRequest };

    if (key == "documents" && e.target.checked) {
      _bulkRequest.items.push({ document_id: value});
    } else if (key == "documents" && !e.target.checked) {
      _.remove(_bulkRequest.items, item => String(item.document_id) == String(value));
    }

    setBulkRequest(_bulkRequest);
  }

  const handleSelectAll = (isSelected, documents) => {
    let _bulkRequest = { ...bulkRequest };
    if (isSelected) {
      _.each(documents, e => _bulkRequest.items.push({ document_id: e.id } ));
    } else {
      let ids = _.map(documents, e => String(e.id));
      _.remove(_bulkRequest.items, function (item, index) {
        return _.includes(ids, String(item.document_id));
      });
    }
    setBulkRequest(_bulkRequest);
  };

  const drawItemsErrors = () => {
    if(_.has(bulkRequest, 'errors.items')){
      return (
        <div className="alert alert-danger">
          {_.map(bulkRequest.errors.items, function(error, index){
            return <span key={`item-error-${ index }`}>{error}</span>
          })}
        </div>
      )
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    headerColumnStyle: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff'
    },
    selected: _.map(bulkRequest?.items || [], item => item.document_id),
    onSelect: (row, isSelect) => {
      let _event = { target: { value: row.id, checked: isSelect } };
      handleBulkRequestData(_event, "documents");
    },
    onSelectAll: (isSelect, rows, e) => {
      handleSelectAll(isSelect, rows);
    },
  }

  const drawTable = () => {
    return (
      <>
        <BootstrapTableCustomFilter
          showBulkMyFilter={ props?.showBulkMyFilter }
          remote={ true }
          keyField="id"
          selectRow={ selectRow }
          data={ documents }
          filter={ filterFactory() }
          columns={ columns }
          pagination={paginationFactory(optionPagination({
            totalSize: totalSize,
            sizePerPage: requestFilterParams?.per_page,
          })) }
          classes="table-responsive height-600"
          onTableChange={ handleTableChange }
          _ref={ props?._ref }
        />
      </>
    );
  }

  return (
    <div className="row">
      <div className="col-12">
      <Label className="d-flex justify-content-center h5 my-4">Lista de documentos</Label>
        { drawItemsErrors() }
        { drawTable() }
      </div>
      <FormGroup className="text-end mt-5">
        <a
          href={ buttonActions.back }
          className="btn btn-default"
        >
          { I18n.t("actions.back") }
        </a>
        { drawSubmitButton() }
      </FormGroup>
    </div>
  )
}

export default BulkRequestDestroyDocumentTable;