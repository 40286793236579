import React, {
  useEffect
} from 'react';

import WorkflowRequirementForm from '../../../workflow/requirement/form';
import KarinLawWorkflowRequirementAttributes from './attributes';

import {
  create
} from './axios'

const KarinLawWorkflowRequirementForm = props => {

  const {
    requirement,
    currentUser
  } = props;

  const defaultRequestParams = {  }
  const formName = 'workflow_requirement'

  useEffect(() => {
    if(!requirement.process_id){
      window.location = Routes.new_karin_law_workflow_requirement_path(defaultRequestParams)
    }
  }, [])

  return(
    <div className="row">
      <div className="col-12">
        <KarinLawWorkflowRequirementAttributes
          requirement={ requirement }
          configuration={{
            formName: formName,
            defaultRequestParams: defaultRequestParams
          }}
          routes={{
            indexRequirementsPath: Routes.karin_law_workflow_requirements_path,
            showRequirementPath: Routes.karin_law_workflow_requirement_path
          }}
          services={{
            createRequirement: create,
          }}
          data={ props.data }
        />
      </div>
    </div>
  )
}

export default KarinLawWorkflowRequirementForm;
