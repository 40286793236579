import React, {
  useEffect,
  useMemo,
  useCallback
} from 'react';

import axios from "axios";

import WorkflowRequirementForm from '../../../workflow/requirement/form';
import KarinLawWorkflowRequirementAttributes from '../../../karin_law/workflow/requirement/attributes';

const PublicWorkflowRequirementForm = props => {

  const {
    requirement,
    currentCustomer,
  } = props;

  const defaultRequestParams = {
    customer_id: currentCustomer.hashid,
    process_id: requirement.process.hashid
  }

  const formName = 'workflow_requirement';

  const createRoutePath = useMemo(() => {
    let route = '';

    if(requirement.module_type == 'Abstract::Workflow::Requirement'){
      route = Routes.public_abstract_workflow_requirements_path
    } else if(requirement.module_type == 'KarinLaw::Workflow::Requirement'){
      route = Routes.public_karin_law_workflow_requirements_path
    }

    return route;
  },[])

  const showRoutePath = useMemo(() => {
    let route = '';

    if(requirement.module_type == 'Abstract::Workflow::Requirement'){
      route = Routes.created_public_abstract_workflow_requirement_path
    } else if( requirement.module_type == 'KarinLaw::Workflow::Requirement'){
      route = Routes.created_public_karin_law_workflow_requirement_path
    }

    return route;
  }, [])


  const FormComponent = useMemo(() => {
    if(requirement.module_type == 'KarinLaw::Workflow::Requirement'){
      return KarinLawWorkflowRequirementAttributes
    } else {
      return WorkflowRequirementForm
    }
  }, [requirement])

  const createService = (requestParams, formData, callback) => {
    let promise = axios({
      method: 'post',
      url: createRoutePath({...{...requestParams}, format: 'json', _options: true }),
      data: formData,
      headers: {
        "X-CSRF-TOKEN": window.compliance.token,
      },
    });

    promise
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(error.response);
      });
  }

  return(
    <div className="row">
      <div className="col-12">
        <FormComponent
          requirement={ requirement }
          configuration={{
            formName: formName,
            defaultRequestParams: defaultRequestParams
          }}
          routes={{
            showRequirementPath: showRoutePath
          }}
          services={{
            createRequirement: createService
          }}
          data={ props?.data }
        />
      </div>
    </div>
  )
}

export default PublicWorkflowRequirementForm;
