import axios from "axios";

export const update = (requestParams, formData, callback) => {
  var promise = axios({
    method: 'put',
    url: Routes.portal_workflow_requirement_stage_path({...{ ...requestParams }, format: 'json', _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}
