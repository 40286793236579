import React, { useState, useEffect } from "react";
import I18n from "i18n-js";
import {
  Label,
  Button,
  FormGroup,
  Input
} from 'reactstrap';

import BulkNotificationSignSignerList from "../../../../bulk/notification/sign/signer_list";

import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from "react-bootstrap-table-next";

import {
  handleStateFilters as bootstrapTableFilterHandleStatesFilters,
  drawInputSearch as bootstrapTableFilterDrawInputSearch,
  searchByText as bootstrapTableFilterSearchByText,
  sortCaret as bootstrapTableFilterSortCaret
} from "../../../../helper/bootstrap-table/filter/helper";

const CorporateBulkNotificationSignForm = props => {
  const formName = "corporate_bulk_notification_sign";
  const signers = props?.signers || [];
  const documentTypes = _.sortBy(_.uniq(_.map(signers, "document_type.name")))

  const [selectedSigners, setSelectedSigners] = useState([]);
  const [statesFilters, setStateFilters] = useState([])

  const headerWithFilters = (column, colIndex, { sortElement, filterElement }) => {
    let _filter = _.find(statesFilters, { "field": column.dataField });
    return(
      <>
        <div className="d-flex justify-content-between" style={{ minWidth: '200px'}}>
          <p className="mb-0">{ column.text }</p>
          <div>
            { sortElement }
            <button type='button' onClick={ e => setStateFilters(bootstrapTableFilterHandleStatesFilters(statesFilters, column, filterElement)) } className="btn btn-link m-0 p-0">
              <i className={ !!_filter?.value ? "far fa-times-circle mt-1 fs-6" : "fas fa-search mt-1 fs-6" }></i>
            </button>
          </div>
        </div>
        { bootstrapTableFilterDrawInputSearch(statesFilters, column, filterElement) }
      </>
    )
  }

  const signerColumns = [
    {
      dataField: "email",
      text: `${I18n.t("activerecord.attributes.signer.email")}`,
      sort: true,
      sortCaret: (order, column) =>  bootstrapTableFilterSortCaret(order, column),
      filter: textFilter({}),
      headerFormatter: headerWithFilters,
      formatter: (value,row,index) => value || I18n.t('no_entry')
    },
    {
      dataField: "name",
      text: `${I18n.t("activerecord.attributes.signer.name")}`,
      filter: textFilter({
        onFilter: bootstrapTableFilterSearchByText.bind(this, 'name')
      }),
      headerFormatter: headerWithFilters,
      formatter: (value,row,index) => value || I18n.t('no_entry')
    },
    {
      dataField: "document_type.name",
      text: `${I18n.t("activerecord.attributes.document.document_type")}`,
      filter: selectFilter({
        options: _.map(documentTypes, type => ({ value: type, label: type })),
      }),
      headerFormatter: headerWithFilters,
      formatter: (value,row,index) => value || I18n.t('no_entry')
    },
    {
      dataField: "document.label",
      text: `${I18n.t("activerecord.attributes.document.label")}`,
      filter: textFilter(),
      headerFormatter: headerWithFilters,
      formatter: (value,row,index) => value || I18n.t('no_entry')
    }
  ];

  const handleSigners = (_signers) => {
    setSelectedSigners(_signers)
  }

  const drawSelectedSignersInput = () => {
    return(
      <>
        { _.map(selectedSigners, function(signer){
          return(
            <div key={ `signer-input-${ signer.id }` }>
              <input
                type="hidden"
                name={ `${formName}[items_attributes][][signer_id]` }
                value={ signer.id }
              />
            </div>
          )
        })}
      </>
    )
  }

  const drawFilterButton = () => {
    return (
      <div className="text-right mb-3 ">
        <button
          className="btn btn-info mr-1 mb-2 mb-md-0 ml-2 text-right"
          data-widget="control-sidebar"
        >
          { I18n.t("actions.filter") }
        </button>
      </div>
    );
  };

  const drawDocumentTypeFilter = () => {
    return(
      <FormGroup>
        <Label>
          { I18n.t("activerecord.attributes.document.document_type") }
        </Label>
        <Input
          type='select'
          onChange={ e => handleDocumentTypeFilter(e?.target?.value) }
        >
          <option value=""></option>
          { _.map(documentTypes, function(documentType){
            return(
              <option
                value={ documentType }
                key={ _.uniqueId('company-filter-select') }
              >
                { documentType }
              </option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  const handleClearFilters = () => {
    // https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Column%20Filter&selectedStory=Clear%20All%20Filters&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
    if(_.isFunction(handleDocumentTypeFilter)){
      handleDocumentTypeFilter('')
    }
    if(_.isFunction(handleNameFilter)){
      handleNameFilter('')
    }
    if(_.isFunction(handleEmailFilter)){
      handleEmailFilter('')
    }
  }

  const drawFilter = () => {
    return(
      <aside className="control-sidebar control-sidebar-light">
        <div className="control-sidebar-content">
          <div className="p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
            <h5 className="font-weight-bold mb-0">
              { I18n.t('common.dashboard.control_sidebar.title') }
            </h5>
            <div className="text-end">
              <button aria-label="Close" className="btn-close" data-widget="control-sidebar" type="button">
              </button></div>
          </div>
          <div className="p-3">

            { drawDocumentTypeFilter() }

            <div className="row">
              <div className="col-12 text-right mt-3">
                <a
                  href='#'
                  onClick={ handleClearFilters }
                >
                  { I18n.t('actions.clean_filter') }
                </a>
              </div>

              <div className="col-12 text-right mt-3">
                <Button
                  color='light'
                  data-widget="control-sidebar"
                  block
                  className='mt-2'
                >
                  { I18n.t('actions.close') }
                </Button>

              </div>
            </div>
          </div>
        </div>
      </aside>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <Label className="d-flex justify-content-center h5 my-4">Lista de firmas pendientes</Label>
        {/*{ drawFilterButton() }
        { drawFilter() }*/}
        <div className="table-responsive">
          <BulkNotificationSignSignerList
            signers={ signers }
            signerColumns={ signerColumns }
            selectedSigners={ selectedSigners }
            handleSigners={ handleSigners }
          />

          { drawSelectedSignersInput() }
        </div>
      </div>
    </div>
  );
};

export default CorporateBulkNotificationSignForm;
