import React, {
  useState,
  useCallback,
  useMemo,
  useEffect
} from 'react';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowRequirementStageAttributes from '../../workflow/requirement_stage/attributes'

const requirementStageFormData = (requirementStage, formName) => {
  let _formData = new FormData();

  _formData.append(`${ formName }[observations]`, requirementStage.observations || '');
  _formData.append(`${ formName }[aasm_state_event]`, requirementStage.aasm_state_event || '');


  if(requirementStage.aasm_state_event == 'to_proceeded' || requirementStage.aasm_state_event == 'to_returned'){
    _formData.append(`${ formName }[to_stage_id]`, requirementStage.to_stage_id || '');
  } else {
    _formData.append(`${ formName }[to_stage_id]`, '');
  }


  if((requirementStage.to_stage_authorized_emails || []).length > 0){
    _.each(requirementStage.to_stage_authorized_emails, email => {
      _formData.append(`${ formName }[to_stage_authorized_emails][]`, email)
    })
  }


  _formData.append(`${ formName }[output_form_attributes][id]`, requirementStage?.output_form?.id || '');

  const outputFieldItems = requirementStage?.output_form?.field_items || [];

  const outputFieldItemsFormName = `${ formName }[output_form_attributes][field_items_attributes]`
  _.each(outputFieldItems, (fieldItem, index) => {

    _formData.append(`${ outputFieldItemsFormName }[${ index }][id]`, fieldItem?.id || '');
    _formData.append(`${ outputFieldItemsFormName }[${ index }][value]`, fieldItem?.value || '');
  })

  const outputFileItems = requirementStage?.output_form?.file_items || [];
  const outputFileItemsFormName = `${ formName }[output_form_attributes][file_items_attributes]`;
  
  _.each(outputFileItems, (fileItem, index) => {
    _formData.append(`${ outputFileItemsFormName }[${ index }][id]`, fileItem?.id || '');
    _formData.append(`${ outputFileItemsFormName }[${ index }][touched_at]`, _.random(0, 100000));

    if(fileItem?.file){
      _formData.append(`${ outputFileItemsFormName }[${ index }][file]`, fileItem?.file || '');
    }else if(fileItem?.reference_to){
      _formData.append(`${ outputFileItemsFormName }[${ index }][reference_to]`, fileItem?.reference_to || '');
    }
  })

  return _formData;
}

const WorkflowRequirementStageModalForm = props => {

  const {
    configuration: {
      defaultRequestParams,
      formName = 'workflow_requirement_stage'
    },
    callbacks: {
      onChangeRequirementAasmEvent: callbackOnChangeRequirementAasmEvent,
      onSuccessSubmit: callbackOnSuccessSubmit
    },
    actions,
    // routes: {
    //   showShortlistPath =
    // },
    services: {
      updateRequirementStage: updateRequirementStageService
    }
  } = props;

  const [requirementStage, setRequirementStage] = useState(props?.requirementStage || {});

  useEffect(() => {
    setRequirementStage(props?.requirementStage);
  }, [props?.requirementStage])

  const openModal = useMemo(() => {
    return !!props?.requirementStage?.aasm_state_event;

  }, [props?.requirementStage?.aasm_state_event])

  const closeModal = useCallback(() => {
    callbackOnChangeRequirementAasmEvent(null)
  }, [])


  const onChangeRequirementStage = (event, key) => {
    let value = event.target.value;

    setRequirementStage(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  // ---------- START SUBMIT ----------
  const onConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirement_stages.form.confirm.title'),
      html: I18n.t('workflow.requirement_stages.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSubmit = () => {
    const formData = requirementStageFormData(requirementStage, formName);

    let requestParams = { ... defaultRequestParams, id: requirementStage.hashid }

    updateRequirementStageService(requestParams, formData, response => {
      if(response.status == 200){
        callbackOnSuccessSubmit()
      } else {
        swalToastBootstrap.fire({
          icon: 'error',
          title: I18n.t('errors.unprocessable_entity')
        });

        setRequirementStage(response.data)
      }
    })
  }
  // ---------- END SUBMIT ----------

  const aasmEventToHumanName = useMemo(() => {

    const event = _.find(requirementStage.events.permitted, { event: requirementStage.aasm_state_event});

    return event?.human_name;
  }, [requirementStage.aasm_state_event])

  const modalTitle = () => {
    const eventHumanName = aasmEventToHumanName;
    const stageName = requirementStage.stage_name;

    let title = '';

    if(eventHumanName){
      title = `${ eventHumanName } - `
    }

    title = title + stageName;
    return title;
  }


  return(
    <Modal
      isOpen={ openModal }
      toggle={ e => closeModal() }
      size={ 'xl' }
    >
      <ModalHeader toggle={ e => closeModal() }>
        { modalTitle() }
      </ModalHeader>

      <ModalBody>
        <WorkflowRequirementStageAttributes
          requirementStage={ requirementStage }
          configuration={{
            defaultRequestParams: defaultRequestParams,
            formName: formName
          }}
          callbacks={{
            onChangeRequirementStage: onChangeRequirementStage
          }}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          color='default'
          onClick={ e => closeModal() }
        >
          { I18n.t('actions.close') }
        </Button>

        <Button
          color={ 'success' }
          onClick={ e => onConfirmSubmit() }
        >
          { I18n.t('actions.save') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default WorkflowRequirementStageModalForm;
