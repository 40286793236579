import React, {
  useCallback,
  useMemo
} from 'react';

import {
  Button,
  UncontrolledCollapse,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody
} from 'reactstrap';

import I18n from 'i18n-js';

import byteSize from 'byte-size';

import {
  KARIN_LAW_DEFAULT_GROUP_NAME
} from '../../requirement_stage/helper'

const fileSize = (file) => {
  const _byteSize = byteSize(file.byte_size);

  return `${ _byteSize }`
}

const WorkflowRequirementCommentList = props => {
  const {
    comments,
    commentPaginator,
    routes,
    configuration,
    callbacks: {
      fetchComments: fetchComments
    }
  } = props;

  const moreCommentsButton = () => {
    if(commentPaginator.currentPage < commentPaginator.totalPages){
      return(
        <div className="text-center mb-2">
          <Button
            size='xs'
            color='default'
            className='px-2'
            onClick={ e => fetchComments(commentPaginator.currentPage + 1) }
          >
            Cargar más
          </Button>
        </div>
      )
    }
  }

  const commentList = () => {
    return(
      <div className="div">
        <div className="col-12">
          { moreCommentsButton() }

          { _.map(comments, comment => {
            return(
              <WorkflowRequirementCommentItem
                key={ `WorkflowRequirementCommentItem-${ comment.id }` }
                comment={ comment }
                routes={ routes }
                configuration={ configuration }
              />
            )
          })}
        </div>
      </div>
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        { commentList() }
      </div>
    </div>
  )
}

export default WorkflowRequirementCommentList;

const WorkflowRequirementCommentItem = props => {
  const {
    comment,
    routes,
    configuration
  } = props;

  const MessageTypeComponent = useMemo(() => {
    if(comment.message_type == 'created_stage'){
      return WorkflowRequirementCommentCreatedStage
    } else if( comment.message_type == 'aasm_stage'){
      return WorkflowRequirementCommentAasmState;
    } else if(comment.message_type == 'karin_law_info'){
      return WorkflowRequirementCommentKarinLawInfo
    } else {
      return WorkflowRequirementCommentDefault
    }
  }, [comment.message_type])

  return(
    <div className="row">
      <div className="col-12">
        <MessageTypeComponent
          comment={ comment }
          routes={ routes }
          configuration={ configuration }
        />
      </div>
    </div>
  )
}

const WorkflowRequirementCommentKarinLawInfo = props => {
  const {
    comment,
    routes,
    configuration
  } = props;

  const metadata = comment?.metadata || {}

  const victimTitle = I18n.t('karin_law.workflow.requirements.form.victim.title')
  const perpetratorTitle = I18n.t('karin_law.workflow.requirements.form.perpetrator.title')
  const representativeTitle = I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_name')


  const outputFieldItemItemByGroup = useCallback((groupName) => {
    const fieldItems = metadata.current_requirement_stage.output_form.field_items;

    return _.filter(fieldItems, item => {
      return item.group_name == groupName
    })
  }, [comment])

  const outputFileItemItemByGroup = useCallback((groupName) => {
    const fileItems = metadata.current_requirement_stage.output_form.file_items;

    return _.filter(fileItems, item => {
      return item.group_name == groupName
    })
  }, [comment])

  const isReportedByVictim = useMemo(() => {
    return metadata.reported_by == 'victim'
  }, [comment])

  const isReportedByRepresentative = useMemo(() => {
    return metadata.reported_by == 'representative'
  }, [comment])

  const reportedByBadge = useMemo(() => {
    let iconClass = "fas fa-user"

    if(isReportedByRepresentative){
      iconClass = "fas fa-user-shield"
    }

    const label = I18n.t(`activerecord.attributes.karin_law/workflow/requirement.reported_by.${ metadata.reported_by }`)

    return(
      <span className={ `badge badge-soft-success` }>
        <i
          className={ `${ iconClass } mr-1` }
          title={ label }
        />
        <span>{ label }</span>
      </span>
    )
  }, [comment])

  const stageIconContainer = useMemo(() => {
    return (
      <div className="icon-container bg-success">
        <i className="fas fa-thumbs-up"></i>
      </div>
    )
  }, [comment])

  const listOutputFieldItemByGroup = (group) => {
    const items = outputFieldItemItemByGroup(group)

    return (
      _.map(items, item => {
        return(
          <div className="list-group-item" key={ `KarinLawItem-${ item.id }` } >
            <span className="small fw-bold">
              { item.name }:&nbsp;
            </span>
            <span className="text-muted">
              { item.value || I18n.t('no_data') }
            </span>
          </div>
        )
      })
    )
  }

  const listOutputFileItemByGroup = (group) => {
    const items = outputFileItemItemByGroup(group)

    return(
      <div className="row mt-3">
        { _.map(items, item => {
          return(
            <WorkflowRequirementCommentStageFile
              key={ `WorkflowRequirementCommentStageFileKarinLaw-${ item.id }`}
              requirementStage={ comment.metadata.current_requirement_stage }
              fileItem={ item }
              configuration={ configuration }
              routes={{
                downloadFileItemPath: routes.downloadOutputFilePath
              }}
            />
          )
        })}
      </div>
    )
  }

  const complaintInfoCard = () => {
    if(true){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    <i className="fas fa-bullhorn"></i>
                    &nbsp; { 'Información de denuncia' }
                  </div>
                </div>
              </div>

              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.reported_by.one') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { function(){
                        if(metadata.reported_by){
                          return I18n.t(`activerecord.attributes.karin_law/workflow/requirement.reported_by.${ metadata.reported_by }`)
                        } else {
                          return I18n.t('no_data')
                        }
                      }()}
                    </span>
                  </div>
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.complaint_reason.one') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { function(){
                        if(metadata.complaint_reason){
                          return I18n.t(`activerecord.attributes.karin_law/workflow/requirement.complaint_reason.${ metadata.complaint_reason }`)
                        } else {
                          return I18n.t('no_data')
                        }
                      }()}
                    </span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const victimCard = () => {
    if(true){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    <i className="fas fa-bullhorn"></i>
                    &nbsp; { victimTitle }
                  </div>
                </div>
              </div>

              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_name') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.victim_name || I18n.t('no_data') }
                    </span>
                  </div>
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_identification_number') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.victim_identification_number || I18n.t('no_data') }
                    </span>
                  </div>
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.victim_email') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.victim_email || I18n.t('no_data') }
                    </span>
                  </div>

                  { listOutputFieldItemByGroup(victimTitle) }
                </ul>

                { listOutputFileItemByGroup(victimTitle) }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const perpetratorCard = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                <div className="font-weight-bold">
                  <i className="fas fa-bullhorn"></i>
                  &nbsp; { perpetratorTitle }
                </div>
              </div>
            </div>

            <div className="card-body">
              <ul className="list-group list-group-flush">
                <div className="list-group-item">
                  <span className="small fw-bold">
                    { I18n.t('activerecord.attributes.karin_law/workflow/requirement.perpetrator_name') }:&nbsp;
                  </span>
                  <span className="text-muted">
                    { metadata.perpetrator_name || I18n.t('no_data') }
                  </span>
                </div>
                <div className="list-group-item">
                  <span className="small fw-bold">
                    { I18n.t('activerecord.attributes.karin_law/workflow/requirement.perpetrator_email') }:&nbsp;
                  </span>
                  <span className="text-muted">
                    { metadata.perpetrator_email || I18n.t('no_data') }
                  </span>
                </div>
                { listOutputFieldItemByGroup(perpetratorTitle) }
              </ul>

              { listOutputFileItemByGroup(perpetratorTitle) }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const representativeCard = () => {
    if(isReportedByRepresentative){
      return(
        <div className="row">
          <div className="col-12">
            <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    <i className="fas fa-bullhorn"></i>
                    &nbsp; { I18n.t('karin_law.workflow.requirements.form.representative.title') }
                  </div>
                </div>
              </div>

              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { representativeTitle }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.representative_name || I18n.t('no_data') }
                    </span>
                  </div>
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_identification_number') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.representative_identification_number || I18n.t('no_data') }
                    </span>
                  </div>
                  <div className="list-group-item">
                    <span className="small fw-bold">
                      { I18n.t('activerecord.attributes.karin_law/workflow/requirement.representative_email') }:&nbsp;
                    </span>
                    <span className="text-muted">
                      { metadata.representative_email || I18n.t('no_data') }
                    </span>
                  </div>
                  { listOutputFieldItemByGroup(representativeTitle) }
                </ul>

                { listOutputFileItemByGroup(representativeTitle) }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const otherCards = () => {
    const fieldItems = metadata.current_requirement_stage.output_form.field_items;
    const fileItems = metadata.current_requirement_stage.output_form.file_items;

    const items = _.concat(fieldItems, fileItems);

    const filteredItems = _.reject(items, item => {
      return _.includes(KARIN_LAW_DEFAULT_GROUP_NAME, item.group_name)
    })

    const itemsGrouped = _.groupBy(filteredItems, item => { return item.group_name })

    return _.map(itemsGrouped, (items, group) => {
      const fieldItems = _.filter(items, item => { return item.data_type != 'file' })
      const fileItems = _.filter(items, item => { return item.data_type == 'file' })

      const targetId = _.camelCase(group || 'without_group')

      return(
        <div className="row" key={ `otherCardContainer-${ targetId }` }>
          <div className="col-12">
            <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                  <div className="font-weight-bold">
                    <i className="fas fa-bullhorn"></i>
                    &nbsp; { group }
                  </div>
                </div>
              </div>

              <div className="card-body">
                <ul className="list-group list-group-flush">
                  { _.map(fieldItems, fieldItem => {
                    return(
                      <div className="list-group-item" key={ `otherCardFieldItem-${ fieldItem.id }` }>
                        <span className="small fw-bold">
                          { fieldItem.name }:&nbsp;
                        </span>
                        <span className="text-muted">
                          { fieldItem.value || I18n.t('no_data') }
                        </span>
                      </div>
                    )
                  })}
                </ul>

                <div className="row mt-3">
                  { _.map(fileItems, fileItem => {
                    return(
                      <WorkflowRequirementCommentStageFile
                        key={ `WorkflowRequirementCommentStageFileOtherCard-${ fileItem.id }` }
                        requirementStage={ comment.metadata.current_requirement_stage }
                        fileItem={ fileItem }
                        configuration={ configuration }
                        routes={{
                          downloadFileItemPath: routes.downloadOutputFilePath
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return(
    <div className="row">
      <div className="col-12">
        <div className="message-container stage-info">
          <div className="card bg-real-light mb-0">
            <div className="card-body">
              <div className="signature_history">
                <div className="signature_history_item">
                  <div className="row">
                    <div className="col-3 col-sm-1 text-right">
                      { stageIconContainer }
                    </div>
                    <div className="col-9 col-sm-11">
                      <h3 className="fs-6 fw-bold small mb-0">Denuncia creada</h3>
                      <small>
                        <ul className="list-unstyled fa-ul ml-0 mb-0 list-inline">
                          <li className="list-inline-item">
                            { reportedByBadge }
                          </li>
                        </ul>
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center">
                        <Button
                          size='xs'
                          outline
                          color='primary'
                          className='px-2 mt-2'
                          id={ `commentMoreInfo-${ comment.id }` }
                        >
                          Más información
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UncontrolledCollapse toggler={ `commentMoreInfo-${ comment.id }` }>
          { complaintInfoCard() }
          { victimCard() }
          { perpetratorCard() }
          { representativeCard() }
          { otherCards() }
        </UncontrolledCollapse>
      </div>
    </div>
  )
}

const WorkflowRequirementCommentCreatedStage = props => {
  const {
    comment
  } = props;

  return(
    <div
      className="message-container chat-info"
    >
      <span>
        { comment.body }
      </span>
    </div>
  )
}

const WorkflowRequirementCommentStageFile = props => {
  const {
    fileItem,
    requirementStage,
    routes,
    configuration: {
      requirementDefaultRequestParams,
      context
    }
  } = props;

  const fileItemIsAttached = useMemo(() => {
    return _.has(fileItem, 'file_attachment.blob_id')
  }, [])

  const fileItemBadgeClass = useMemo(() => {
    return fileItemIsAttached ? 'badge-primary' : 'badge-info'
  }, [])

  const fileItemIcon = useMemo(() => {
    if(fileItemIsAttached){
      return <i className="fas fa-download"></i>
    } else {
      return <i className="fas fa-clock"></i>
    }
  }, [])

  const downloadFileItemPath = useMemo(() => {
    let requestParams = {
      ... requirementDefaultRequestParams,
      file_item_id: fileItem.id,
      _options: true
    }

    if(context == 'public'){
      requestParams = { ... requestParams, requirement_stage_id: requirementStage.hashid }
    } else {
      requestParams = { ... requestParams, id: requirementStage.hashid }
    }

    return routes.downloadFileItemPath(requestParams)
  }, [fileItem])

  return(
    <div className="col-md-6" key={ `outputFileItem-${ fileItem.id }`}>
      <div className="card bg-white card-material border-0 hover-card">
        <div className="card-body d-flex">
          <div className="doc-extension-indicator position-relative">
            <div
              className={ `badge position-absolute ${ fileItemBadgeClass } ` }
            >
              { fileItemIcon }
            </div>
            <div className="doc-item"></div>
          </div>
          <div className="small lh-sm ml-2">
            { function(){
              if(fileItemIsAttached){
                return(
                  <>
                    <a
                      href={ downloadFileItemPath }
                      download={ true }
                      className='d-block fw-bold mb-1'
                    >
                      { fileItem.name }
                    </a>
                    <ul className="list-unstyled list-inline mb-2 mb-sm-0 small">
                      <li className="list-inline-item mr-3">{ fileSize(fileItem.file_attachment) }</li>
                    </ul>
                  </>
                )
              } else {
                return(
                  <>
                    <strong className='d-block'>
                      { fileItem.name }
                    </strong>
                    { I18n.t('no_entry') }
                  </>
                )
              }
            }()}
          </div>
        </div>
      </div>
    </div>
  )
}

const WorkflowRequirementCommentAasmState = props => {
  const {
    comment,
    routes,
    configuration,
    comment: { metadata },
  } = props;

  const aasmStateIconClass = useMemo(() => {
    if(comment.metadata.aasm_state == 'pending'){
      return 'fas fa-clock'
    } else if( comment.metadata.aasm_state == 'proceeded'){
      return 'fas fa-check-circle'
    } else if(comment.metadata.aasm_state == 'returned'){
      return 'fas fa-undo'
    } else if(comment.metadata.aasm_state == 'rejected'){
      return 'fas fa-ban'
    } else if(comment.metadata.aasm_state == 'completed'){
      return 'fas fa-clipboard-check'
    }
  }, [comment])

  const aasmStateColor = useMemo(() => {
    if(comment.metadata.aasm_state == 'pending'){
      return 'info'
    } else if( comment.metadata.aasm_state == 'proceeded'){
      return 'success'
    } else if(comment.metadata.aasm_state == 'returned'){
      return 'warning'
    } else if(comment.metadata.aasm_state == 'rejected'){
      return 'danger'
    } else if(comment.metadata.aasm_state == 'completed'){
      return 'success'
    }
  }, [comment])

  const aasmStateHumanName = useMemo(() => {
    return I18n.t(`activerecord.attributes.workflow/requirement_stage.aasm_state.${ comment.metadata.aasm_state }`)
  }, [comment])

  const aasmStateBadge = useMemo(() => {
    return(
      <span className={ `badge badge-soft-${ aasmStateColor }` }>
        <i
          className={ `${ aasmStateIconClass } mr-1` }
          title="Aceptado"
        />
        <span>{ aasmStateHumanName }</span>
      </span>
    )
  }, [comment])

  const stageIconContainer = useMemo(() => {
    if(metadata.node_type == 'input'){
      return <div className="icon-container bg-success"><i className="fas fa-map-marker-alt"></i></div>
    } else if(metadata.node_type == 'output'){
      return <div className="icon-container bg-danger"><i className="fas fa-flag-checkered"></i></div>
    } else {
      return (
        <div
          className={ `icon-container bg-${ aasmStateColor }` }
        >
          <i
            className={ aasmStateIconClass }
          />
        </div>
      )
    }
  }, [comment])

  const responsibleName = useMemo(() => {
    return comment?.metadata?.responsible?.email || 'Anónimo'
  }, [comment])

  const createdAtToS = useMemo(() => {
    return moment(comment.metadata.created_at).format("DD/MM/YYYY HH:mm:ss")
  }, [comment])

  const completedAtToS = useMemo(() => {
    return moment(comment.metadata.completed_at).format("DD/MM/YYYY HH:mm:ss")
  }, [comment])

  const intervalTimeCompletedToS = useMemo(() => {
    return comment.metadata.interval_time_completed || ' - '
  }, [comment])

  const observationsLi = useMemo(() => {
    if(comment.metadata.observations){
      return(
        <li className="list-inline-item small ml-4">
          <span className="fa-li">
            <i className="far fa-comment-alt"></i></span>
            <strong>Observaciones: </strong>
            { comment.metadata.observations }
        </li>
      )
    }
  }, [comment])

  const outputFieldItems = useMemo(() => {
    const fieldItems = comment.metadata.output_form.field_items;

    if(fieldItems.length > 0 ){
      return(
        <ul className="list-group list-group-flush">
          { _.map(fieldItems, fieldItem => {
            return(
              <div className="list-group-item" key={ `outputFieldItem-${ fieldItem.id }` }>
                <span className="small fw-bold">
                  { fieldItem.name }: &nbsp;
                </span>
                <span
                  className="text-muted"
                >
                  { fieldItem.value || I18n.t('no_entry') }
                </span>
              </div>
            )
          })}
        </ul>
      )
    }
  }, [comment])

  const outputFileItems = useMemo(() => {
    const fileItems = comment.metadata.output_form.file_items;

    if(fileItems.length > 0){
      return(
        <div className="row mt-3">
          { _.map(fileItems, fileItem => {
            return(
              <WorkflowRequirementCommentStageFile
                key={ `WorkflowRequirementCommentStageFile-${ fileItem.id }` }
                requirementStage={ comment.metadata }
                fileItem={ fileItem }
                configuration={ configuration }
                routes={{
                  downloadFileItemPath: routes.downloadOutputFilePath
                }}
              />
            )

          })}
        </div>
      )
    }

  }, [comment])

  const outputItems = useMemo(() => {
    const items = _.concat(
      comment.metadata.output_form.field_items,
      comment.metadata.output_form.file_items
    )

    const groupedItems = _.groupBy(items, item => {
      return item.group_name
    });

    return(
      <div className="row">
        <div className="col-12">
          { _.map(groupedItems, (items, group) => {
            const fieldItems = _.filter(items, item => { return item.data_type != 'file'});
            const fileItems = _.filter(items, item => { return item.data_type == 'file'});

            const targetId = _.camelCase(group || 'without_group')

            return(
              <div className="row" key={ `otherCardContainer-${ targetId }` }>
                <div className="col-12">
                  <div className="card card-material bg-real-light card-material border-0 overflow-hidden mb-4">
                    <div className="card-header">
                      <div className="d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap">
                        <div className="font-weight-bold">
                          <i className="fas fa-bullhorn"></i>
                          &nbsp; { group || 'Sin Grupo' }
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <ul className="list-group list-group-flush">
                        { _.map(fieldItems, fieldItem => {
                          return(
                            <div className="list-group-item" key={ `otherCardFieldItem-${ fieldItem.id }` }>
                              <span className="small fw-bold">
                                { fieldItem.name }:&nbsp;
                              </span>
                              <span className="text-muted">
                                { fieldItem.value }
                              </span>
                            </div>
                          )
                        })}
                      </ul>

                      <div className="row mt-3">
                        { _.map(fileItems, fileItem => {
                          return(
                            <WorkflowRequirementCommentStageFile
                              key={ `WorkflowRequirementCommentStageFileOtherCard-${ fileItem.id }` }
                              requirementStage={ comment.metadata }
                              fileItem={ fileItem }
                              configuration={ configuration }
                              routes={{
                                downloadFileItemPath: routes.downloadOutputFilePath
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }, [comment])

  return(
    <div className="row">
      <div className="col-12">
        <div className="message-container stage-info">
          <div className="card bg-real-light mb-0">
            <div className="card-body">
              <div className="signature_history">
                <div className="signature_history_item">
                  <div className="row">
                    <div className="col-3 col-sm-1 text-right">
                      { stageIconContainer }
                    </div>
                    <div className="col-9 col-sm-11">
                      <h3 className="fs-6 fw-bold small mb-0">{ comment?.metadata?.stage_name }</h3>
                      <small>
                        <ul className="list-unstyled fa-ul ml-0 mb-0 list-inline">
                          <li className="list-inline-item">
                            { aasmStateBadge }
                          </li>
                          <li className="list-inline-item small ml-4">
                            <span className="fa-li">
                              <i className="fas fa-id-card-alt"></i></span>
                              <strong>Completada por: </strong>
                              { responsibleName }
                          </li>
                          <li className="list-inline-item small ml-4">
                            <span className="fa-li">
                              <i className="far fa-calendar-alt"></i></span>
                              <strong>Fecha de creación: </strong>
                              { createdAtToS }
                          </li>
                          <li className="list-inline-item small ml-4">
                            <span className="fa-li">
                              <i className="far fa-calendar-alt"></i></span>
                              <strong>Completado el: </strong>
                              { completedAtToS }
                          </li>
                          <li className="list-inline-item small ml-4">
                            <span className="fa-li">
                              <i className="fas fa-stopwatch"></i></span>
                              <strong>Tiempo de etapa: </strong>
                              { intervalTimeCompletedToS }
                          </li>
                          { observationsLi }
                        </ul>
                      </small>
                    </div>
                  </div>
                  { function(){
                    if(configuration?.showInfoStage){
                      return(
                        <div className="row">
                          <div className="col-12">
                            <div className="text-center">
                              <Button
                                size='xs'
                                outline
                                color='primary'
                                className='px-2 mt-2'
                                id={ `commentMoreInfo-${ comment.id }` }
                              >
                                Más información
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  }()}
                </div>
              </div>
            </div>
          </div>
        </div>

        { function(){
          if(configuration?.showInfoStage){
            return(
              <UncontrolledCollapse toggler={ `commentMoreInfo-${ comment.id }` }>
                { outputItems }
              </UncontrolledCollapse>
            )
          }
        }()}
      </div>
    </div>
  )
}

const WorkflowRequirementCommentDefault = props => {
  const {
    comment,
    routes,
    configuration: {
      commentDefaultRequestParams
    }
  } = props

  const downloadAttachUrl = useCallback((file) => {
    const requestParams = {
      ... commentDefaultRequestParams,
      attach_id: file.id,
      id: comment.hashid,
      _options: true
    }

    return routes.downloadAttachCommentPath(requestParams)
  }, [])

  const fileList = () => {
    if(comment.files.length > 0){
      return(
        <div className="row">
          { _.map(comment.files, file => {
            return(
              <a href={ downloadAttachUrl(file) } download={ true } className='text-decoration-none' key={ `document-${ file.id }`}>
                <div className="col-12">
                  <div className="document-info d-flex justify-content-start align-items-center mb-2">
                    <div className="doc-extension-indicator position-relative">
                      <div className="badge position-absolute badge-primary">
                        <span className="text-decoration-none">
                          <i className="fas fa-download"></i>
                        </span>
                      </div>
                      <div className="doc-item"></div>
                    </div>
                    <div className="d-inline ml-3 text-left">
                      <p className="fs-6 font-weight-bold d-block text-capitalize mb-1">
                        <span className="text-decoration-none fw-bold">
                          { file.filename }
                        </span>
                      </p>
                      <ul className="list-unstyled list-inline mb-2 mb-sm-0 small">
                        <li className="list-inline-item mr-3">{ fileSize(file) }</li>
                        {/*<li className="list-inline-item mr-3">PDF</li>*/}
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
      )
    }
  }

  const createdAtFormat = () => {
    return moment(comment.created_at).format('DD/MM/YYYY HH:mm:ss')
  }

  return(
    <div className={`message-container chat-card answer`} id={ `comment-${ comment.id }`}>
      {/*<img className="avatar-img" src="/assets/user_avatar-8076daa6f5b30be9dc005d57ba79feb69bfb21b095e8dbd80f9433aa0e23d4d3.png"/>*/}
      <div className="message-card-container">
        <div className="card message-card">
          <p>{ comment.body }</p>

          { fileList() }

          <span className="date-info">
            { `${ comment.author_name } - ${ createdAtFormat() }` }
          </span>

        </div>
      </div>
    </div>
  )
}
