import React from 'react';

import WorkflowRequirementStageToolbarEvent from '../../../workflow/requirement_stage/toolbar_event'

import {
  update
} from './axios'

const AbstractWorkflowRequirementStageToolbarEvent = props => {

  const {
    requirementStage,
    currentCustomer,
    currentEntityType,
    policies
  } = props;


  const defaultRequestParams = {
    id: requirementStage.hashid,
    current_entity_type_id: currentEntityType.hashid
  }

  const onSuccessSubmit = () => {
    window.location = Routes.abstract_workflow_requirement_path(
      currentEntityType.hashid, requirementStage.requirement.hashid
    );
  }

  return(
    <div className="row">
      <div className="col-12">
        <WorkflowRequirementStageToolbarEvent
          requirementStage={ requirementStage }
          policies={ policies }
          configuration={{
            defaultRequestParams: defaultRequestParams
          }}
          routes={{
            showShortlistPath: Routes.abstract_shortlist_assessment_load_path
          }}
          callbacks={{
            onSuccessSubmit: onSuccessSubmit
          }}
          services={{
            updateRequirementStage: update
          }}
        />
      </div>
    </div>
  )
}

export default AbstractWorkflowRequirementStageToolbarEvent;

