import React, { useState, useRef } from 'react';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { pageListRenderer, options as optionPagination } from "../../helper/pagination";
import LaboralBulkMyFilterForm from "../../laboral/bulk/my_filter/form";
import LaboralBulkMyFilterList from "../../laboral/bulk/my_filter/list";

const BootstrapTableCustomFilter = (props) => {

  let {
    showBulkMyFilter,
    remote,
    keyField,
    selectRow,
    data,
    filter,
    columns,
    pagination,
    classes,
    onTableChange
  } = props;

  const [showSearchFilters, setShowSearchFilters] = useState(false)
  const [myFilterCreated, setMyFilterCreated] = useState(false)

  const handleShowSearchFilters = (e) => {
    setShowSearchFilters(!showSearchFilters)
  }

  const drawBulkMyFilter = () => {
    if(showBulkMyFilter){
      return(
        <>
          <LaboralBulkMyFilterList
            bootstrapTableRef={props?._ref}
            myFilterCreated={ myFilterCreated }
            setMyFilterCreated={ setMyFilterCreated }
          />

          <LaboralBulkMyFilterForm
            bootstrapTableRef={props?._ref}
            setMyFilterCreated={ setMyFilterCreated }
          />
        </>
      )
    }
  }

  return(
    <div className="row">
      <div className="d-flex">
        { drawBulkMyFilter() }
        <div className="ms-auto">
          <button
            type='button'
            onClick={ e => handleShowSearchFilters(e) }
            className='btn btn-success mb-2'
          >
            { showSearchFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
          </button>
        </div>
      </div>
      <div className="col-12">
        <BootstrapTable
          { ... {remote,
            keyField,
            selectRow,
            data,
            filter,
            columns,
            pagination,
            classes,
            onTableChange
            }
          }
          ref={ props?._ref }
          showSearchFilters={ showSearchFilters }
          handleShowSearchFilters={ handleShowSearchFilters }
        />
      </div>
    </div>
  )
}


export default BootstrapTableCustomFilter;
