import React, { useState, useEffect, useRef } from "react";
import { FormGroup, FormFeedback, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import { index, create } from "../../../laboral/bulk/my_filter/axios";
import I18n from 'i18n-js';

import FieldError from "../../../helper/FieldError";

const LaboralBulkMyFilterForm = (props) => {
  const formName = props?.formName || 'laboral_bulk_my_filter'

  const bootstrapTableRef = props?.bootstrapTableRef;
  const currFilters = bootstrapTableRef?.current?.filterContext?.currFilters;


  const [modal, setModal] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({})

  const drawFilter = (value, key) => {
    let column = _.find(bootstrapTableRef?.current?.table?.props?.columns || {}, { dataField: key } )

    let filterValue = value?.filterVal;

    if((column?.filter?.props?.options || []).length > 0){
      let option = _.find(column.filter.props.options, function(_option){
        return String(_option.value) == String(filterValue)
      });

      filterValue = option?.label || value?.filterVal;
    }

    return(
      <div key={_.uniqueId()}>
        <dt className="col-sm-12">
          { column?.text }
        </dt>
        <dd className="col-sm-12">
          { filterValue || I18n.t("no_entry") }
        </dd>
      </div>
    )
  }

  const drawModalForm = () => {
    return (
      <div>
        <Modal isOpen={ modal } toggle={ e => setModal(false) }>
          <ModalHeader toggle={ e => setModal(false)}>Mis Filtros</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-4'>
                <FormGroup>
                  <Label>Nombre</Label>
                  <FieldError errors={errors.name}>
                    <Input
                      onChange={e => setName(e.target.value)}
                    />
                  </FieldError>
                </FormGroup>

                <dl className="row">
                  { _.map(currFilters, function(value, key){
                    return drawFilter(value, key)
                  })}
                </dl>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={ e => setModal(false) }
            >
              { I18n.t('actions.close') }
            </Button>
            <Button
              color="success"
              type="button"
              onClick={ handleSubmit }
            >
              { I18n.t('actions.save') }
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  const handleSubmit = () => {
    let formData = getFormData();
    setDisabledSubmitButton(true);

    create(formData, response => {
      if (response.status == 201) {
        setModal(false)
        swal.fire({
          position: 'center',
          icon: 'success',
          title: I18n.t(
            "bulk.my_filters.create.successfully"
          ),
          showConfirmButton: true,
        })

        if(_.isFunction(props?.setMyFilterCreated)){
          props.setMyFilterCreated(true)
        }
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  const getFormData = () => {
    let _formData = new FormData();
    let columns = bootstrapTableRef.current.table.props.columns;


    _formData.append( `${formName}[name]`, name || "" );

    _.each(currFilters, function(value, key){

      let column = _.find(bootstrapTableRef.current.table.props.columns, { dataField: key });

      _formData.append(`${ formName }[filters][filters][${  column.myFilter }]`, value?.filterVal || "");
    })

    return _formData;
  };

  const drawBtnNewFilter = () => {
    let hasFilter = _.some(_.map(currFilters, f => _.has(f, 'filterVal')))
    if(hasFilter){
      return(
        <Button
          className='mb-2 ml-2'
          color="info"
          onClick={ e => setModal(true)}
          outline
        >
          { `${ I18n.t("actions.create") } filtro` }
        </Button>
      )
    }
  }

  return(
    <>
      { drawBtnNewFilter() }
      { drawModalForm() }
    </>
  )
}

export default LaboralBulkMyFilterForm;
