import React, { useState } from 'react';
import I18n from "i18n-js";

import {
  FormGroup,
  Button
} from 'reactstrap';


import BulkRequestDocumentEntityTab from './entity_tab';
import BulkRequestDocumentConfigTab from './config_tab';

import { scrollToTop } from "../../../helper/form";

const BulkRequestDocumentForm = props => {
  const [currentTab, setCurrentTab] = useState('entities');
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false)
  const [bulkRequest, setBulkRequest] = useState(props?.bulkRequest || {});

  const {
    bootstrapTableConfiguration,
    actions,
    data,
    configuration,
    configuration: {
      formName,
      context,
      entity: entityConfiguration,
      shortlist: shortlistConfiguration
    },
  } = props;

  const {
    indexBulkRequestPath,
    showBulkRequestPath,
    createBulkRequest
  } = actions;

  const { defaultRequestParams } = configuration;
  const { key: entityKey, form: entityForm } = entityConfiguration;
  // ---------------- START TABS ----------------

  const navTabs = () => {
    return(
      <ul className="nav nav-pills nav-fill" role="tablist" id="tabRequest">
        <li className="nav-item" role="presentation">
          <button
            className={ `nav-link ${ currentTab == "entities" ? "active" : "" }` }
            id="entitiesTab"
            data-bs-toggle="tab"
            data-bs-target="#entities"
            role="tab"
            aria-controls="entities"
            onClick={ e => setCurrentTab('entities') }
            title={ entityConfiguration.navButtonTitle }
          >
            <small>Paso 1 </small>
            <span className="font-weight-bold">
              { entityConfiguration.label.plural }
            </span>
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${ currentTab == "config" ? "active" : "" }`}
            id="configTab"
            data-bs-toggle="tab"
            data-bs-target="#config"
            role="tab"
            aria-controls="config"
            onClick={ e => setCurrentTab('config') }
            title={ "Configuración" }
          >
            <small>Paso 2 </small>
            <span className="font-weight-bold">
              { "Configuración" }
            </span>
          </button>
        </li>
      </ul>
    )
  }

  const navContent = () => {
    return(
      <div className="tab-content pt-3" id="myTabContent">
        <div
          className={ `tab-pane fade ${ currentTab == "entities" ? "show active" : "" }` }
          id="entities"
          role="tabpanel"
          aria-labelledby="entities"
        >
          <BulkRequestDocumentEntityTab
            bulkRequest={ bulkRequest }
            setBulkRequest={ setBulkRequest }
            bootstrapTableConfiguration={ bootstrapTableConfiguration }
            actions={ actions }
            configuration={ configuration }
          />
        </div>

        <div
          className={ `tab-pane fade ${ currentTab == "config" ? "show active" : ""}` }
          id="config"
          role="tabpanel"
          aria-labelledby="config"
        >
          <BulkRequestDocumentConfigTab
            bulkRequest={ bulkRequest }
            setBulkRequest={ setBulkRequest }
            data={ data }
            configuration={ configuration }
          />
        </div>
      </div>
    )
  }

  const onClickTab = tab => {
    setCurrentTab(tab);
    scrollToTop()
  }

  const entityTabButton = () => {
    if (currentTab == "config") {
      return (
        <Button
          color="default"
          className="ml-2"
          onClick={ e => onClickTab("entities") }
          title={ entityConfiguration?.label?.plural }
        >
          <i
            className="fas fa-arrow-left mr-2"
            aria-hidden="true"
            title="Atrás"
          />
          Volver al Paso 1 { entityConfiguration?.label?.plural }
        </Button>
      );
    }
  }

  const configTabButton = () => {
    if (currentTab == "entities") {
      return (
        <Button
          color="primary"
          className="ml-2"
          onClick={e => onClickTab("config")}
          title="Configuración"
        >
          Ir al paso 2 Configuración
          <i
            className="fas fa-arrow-right ml-2"
            aria-hidden="true"
            title="Siguiente"
          />
        </Button>
      );
    }
  }
  // ---------------- END TABS ----------------


  // ---------------- START SUBMIT ----------------
  const submitButton = () => {
    return (
      <Button
        color="success"
        className="ml-2"
        onClick={ onSubmitConfirm }
        disabled={ submitButtonIsDisabled }
      >
        { submitButtonText() }
      </Button>
    );
  }

  const submitButtonText = () => {
    if(submitButtonIsDisabled){
      return "Guardando información ...";
    } else {
      return "Enviar Solicitud";
    }
  }

  const onSubmitConfirm = () => {
    swalWithBootstrap.fire({
      title: "Realizar solicitud masiva",
      html: "¿Estás seguro de realizar la solicitud masiva?"
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit();
      }
    });
  }

  const onSubmit = () => {
    const _formData = formData();

    setSubmitButtonIsDisabled(true);

    createBulkRequest(defaultRequestParams, _formData, response => {
      if (response.status == 201) {
        window.location = showBulkRequestPath(
          { ... defaultRequestParams, id: response.data.hashid, _options: true }
        );
      } else if (response.status == 422) {
        setBulkRequest(response.data);

        if (_.has(response.data.errors, "entity_items")) {
          setCurrentTab("entities");
        } else {
          setCurrentTab("config");
        }
        setSubmitButtonIsDisabled(false);
        scrollToTop();
      }
    });
  }

  const formData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[scheduled_at]`, bulkRequest?.scheduled_at);
    _formData.append(`${ formName }[is_scheduled]`, bulkRequest?.is_scheduled);

    _.each(bulkRequest[entityKey], (entity, entitiesIndex) => {
      _formData.append(`${ formName }[${ entityForm.entityItemsAttributes.key }][${ entitiesIndex }][${ entityForm.entityItemsAttributes.entityId }]`, entity.id || "")
    })

    _.each(bulkRequest.configs, (config, configIndex ) => {
      const formNameConfig = `${ formName }[configs_attributes][${ configIndex }]`

      _formData.append(`${ formNameConfig }[id]`, config.id || "");
      _formData.append(`${ formNameConfig }[document_type_id]`, config.document_type_id || "");
      _formData.append(`${ formNameConfig }[label]`, config.label || "");
      _formData.append(`${ formNameConfig }[require_signers]`, config.require_signers ? true : false);
      _formData.append(`${ formNameConfig }[require_signers_order]`, config.require_signers_order ? true : false);
      _formData.append(`${ formNameConfig }[template_id]`, config.template_id || "");
      _formData.append(`${ formNameConfig }[autocompleted_by_entity]`, config?.autocompleted_by_entity);
      _formData.append(`${ formNameConfig }[uploaded_by]`, config.uploaded_by || "");
      _formData.append(`${ formNameConfig }[custom_field_id]`, config.custom_field_id || "");
      _formData.append(`${ formNameConfig }[require_fao]`, config.require_fao ? true : false);
      _formData.append(`${ formNameConfig }[require_signature_deadline]`, config.require_signature_deadline ? true : false);
      _formData.append(`${ formNameConfig }[signature_deadline_at]`, config.signature_deadline_at || "");
      _formData.append(`${ formNameConfig }[generate_viewer_table]`, config.generate_viewer_table ? true : false);
      _formData.append(`${ formNameConfig }[keep_format]`, config.keep_format ? true : false);
      _formData.append(`${ formNameConfig }[require_upload_deadline]`, config.require_upload_deadline ? true : false);
      _formData.append(`${ formNameConfig }[upload_deadline_at]`, config.upload_deadline_at || "");

      if(context == 'laboral_bulk' && shortlistConfiguration?.enabled){
        _formData.append(`${ formNameConfig }[shortlist_project_id]`, config.shortlist_project_id || '');
        _formData.append(`${ formNameConfig }[shortlist_project_name]`, config.shortlist_project_name || '');
      }

      if(context == 'corporate_bulk' && config.requested_to){
        _formData.append(`${ formNameConfig }[requested_to]`, config.requested_to || "");
      }

      if(config.file && config.uploaded_by == 'upload_file_user'){
        _formData.append(`${ formNameConfig }[file]`, config.file || "");
      }

      _.each(config.signers, (signer, signerIndex) => {
        const formNameConfigSigner = `${ formNameConfig }[signers_attributes][${ signerIndex }]`;

        _formData.append(`${ formNameConfigSigner }[id]`, signer?.id || "");
        _formData.append(`${ formNameConfigSigner }[email]`, signer?.email || "");
        _formData.append(`${ formNameConfigSigner }[name]`, signer?.name || "");
        _formData.append(`${ formNameConfigSigner }[order]`, signer?.order || 0);
        _formData.append(`${ formNameConfigSigner }[role]`, signer?.role || "");
        _formData.append(`${ formNameConfigSigner }[employee_sign]`, signer?.employee_sign ? true : false);
        _formData.append(`${ formNameConfigSigner }[member_sign]`, signer?.member_sign ? true : false);
        _formData.append(`${ formNameConfigSigner }[email_notification_sign]`, signer?.email_notification_sign ? true : false);
        _formData.append(`${ formNameConfigSigner }[custom_field_id]`, signer?.custom_field_id || "");
        _formData.append(`${ formNameConfigSigner }[first_surname]`, signer?.first_surname || "");
        _formData.append(`${ formNameConfigSigner }[last_surname]`, signer?.last_surname || "");
        _formData.append(`${ formNameConfigSigner }[identification_number]`, signer?.identification_number || "");
        _formData.append(`${ formNameConfigSigner }[is_viewer]`, signer?.is_viewer ? true : false);
        _formData.append(`${ formNameConfigSigner }[_destroy]`, signer?._destroy ? true : false);
      })
    })

    return _formData
  }

  // ---------------- END SUBMIT ----------------

  return(
    <div className="row">
      <div className="col-12">
        { navTabs() }
        { navContent() }

        <FormGroup className="text-end mt-5">
          <div className="mb-3">
            { entityTabButton() }
            { configTabButton() }
          </div>

          {currentTab == "config" &&
            <div className="d-flex align-items-center justify-content-end">
              { submitButton() }
            </div>
          }
        </FormGroup>
      </div>
    </div>
  )
}

export default BulkRequestDocumentForm;
